<template>
    <div class="form-access">
        <el-form ref="registrationForm" :model="registrationForm" :rules="registrationFormRules">
            <div class="mb-3">
                <img class="img-fluid" src="./../../assets/img/infibo-logo.svg" alt="inFibo Logo">
            </div>
            <p class="text-muted text-center">{{ $t('registerNewAccount') }}</p>
            <el-form-item prop="email">
                <el-input
                    clearable
                    v-model="registrationForm.email"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterEmail')"
                    @keyup.enter.native="registrationForm"/>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    clearable
                    show-password
                    v-model="registrationForm.password"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterPassword')"
                    @keyup.enter.native="submitRegistrationForm"/>
            </el-form-item>
            <el-form-item prop="password_confirmation">
                <el-input
                    clearable
                    show-password
                    v-model="registrationForm.password_confirmation"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterPasswordConfirmation')"
                    @keyup.enter.native="submitRegistrationForm"/>
            </el-form-item>
            <el-form-item prop="invite">
                <el-input
                    clearable
                    v-model="registrationForm.invite"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterInvite')"
                    @keyup.enter.native="submitRegistrationForm"/>
            </el-form-item>
            <el-form-item prop="tos_accepted">
                <el-checkbox
                    :disabled="profileLoadingState"
                    v-model="registrationForm.tos_accepted" >
                    <span>{{ $t('tos1') }}</span><router-link to="/tos" class="text-primary">{{ $t('tos2') }}</router-link><span>{{ $t('tos3') }}</span>
                </el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="w-100"
                    type="primary"
                    @click="submitRegistrationForm"
                    :disabled="profileLoadingState"
                    :loading="profileLoadingState">{{ $t('register') }}</el-button>
            </el-form-item>
        </el-form>
        <div class="form-group text-center">
            <router-link class="text-muted text-decoration-none" to="/login">{{ $t('goToLogin') }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Register',
    i18n: {
        messages: {
            en: {
                registerNewAccount: 'Register new account',
                enterEmail: 'Enter email',
                incorrectEmail: 'Email is not a valid email',
                password: 'Password',
                enterPassword: 'Enter password',
                minPasswordLengthError: 'Must be at least 6 characters',
                enterPasswordConfirmation: 'Enter password confirmation',
                passwordsDontMatch: 'Passwords do not match',
                invite: 'Invite',
                enterInvite: 'Enter invite',
                register: 'Register',
                goToLogin: 'Go to Login',
                tos1: 'I agree, that I have read ',
                tos2: 'Risk Warning and Terms of Services',
                tos3: ', understand them and agree to be bound by them.',
                tosMustBeAccepted: 'Term of services must be accepted',
                youHaveSuccessfullyRegistered: 'You have successfully registered',

                responseErrors: {
                    unknownErrorTitle: 'Unknown error',
                    unknownErrorMessage: 'Contact support',

                    networkErrorTitle: 'Network error',
                    networkErrorMessage: 'Check your connection or try again later',

                    wrongEmailOrInviteTitle: 'Validation error',
                    wrongEmailOrInviteMessage: 'Check the entered data and try again',

                    serverErrorTitle: 'Server error',
                    serverErrorMessage: 'Contact support',
                }
            },
            ru: {
                registerNewAccount: 'Регистрация нового аккаунта',
                enterEmail: 'Введите email',
                incorrectEmail: 'Введен неверный email',
                password: 'Пароль',
                enterPassword: 'Введите пароль',
                minPasswordLengthError: 'Должен быть не меньше 6 символов',
                enterPasswordConfirmation: 'Введите подтверждение пароля',
                passwordsDontMatch: 'Пароли не совпадают',
                invite: 'Инвайт',
                enterInvite: 'Введите инвайт',
                register: 'Регистрация',
                goToLogin: 'Вернуться ко Входу',
                tos1: 'Я подтверждаю, что прочитал ',
                tos2: 'Предупреждение о риске и условия предоставления услуг',
                tos3: ', понял их и согласен с ними.',
                tosMustBeAccepted: 'Вы должны согласиться с условиями',
                youHaveSuccessfullyRegistered: 'Вы успешно зарегистрированы',

                responseErrors: {
                    unknownErrorTitle: 'Неизвестная ошибка',
                    unknownErrorMessage: 'Свяжитесь со службой поддержки',

                    networkErrorTitle: 'Ошибка сети',
                    networkErrorMessage: 'Проверьте подключение или повторите попытку позже',

                    wrongEmailOrInviteTitle: 'Ошибка валидации',
                    wrongEmailOrInviteMessage: 'Проверте введенные данные и повторите попытку',

                    serverErrorTitle: 'Ошибка сервера',
                    serverErrorMessage: 'Свяжитесь со службой поддержки',
                }
            }
        }
    },
    data: function () {
        const validatePasswordConfirmation = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(String(this.$t('enterPasswordConfirmation'))))
            } else if (value !== this.registrationForm.password) {
                callback(new Error(String(this.$t('passwordsDontMatch'))))
            } else {
                callback()
            }
        }
        return {
            registrationForm: {
                email: '',
                password: '',
                password_confirmation: '',
                invite: '',
                tos_accepted: false,
            },
            registrationFormRules: {
                email: [
                    {required: true, message: () => this.$t('enterEmail')},
                    {type: 'email', message: () => this.$t('incorrectEmail')},
                ],
                password: [
                    {required: true, message: () => this.$t('enterPassword')},
                    {min: 6, message: () => this.$t('minPasswordLengthError')}
                ],
                password_confirmation: [
                    {validator: validatePasswordConfirmation}
                ],
                invite: [
                    {required: true, message: () => this.$t('enterInvite')}
                ],
                tos_accepted: [
                    {type: 'enum', enum: [true], message: () => this.$t('tosMustBeAccepted')}
                ],
            }
        }
    },
    computed: {
        profileLoadingState: function () {
            return this.$store.getters.PROFILE_LOADING_STATE
        }
    },
    methods: {
        submitRegistrationForm: function () {
            this.$refs['registrationForm'].validate(valid => {
                if(!valid) {
                    return
                }

                this.$store.dispatch('PROFILE_CREATE_REQUEST', this.registrationForm)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Ok',
                            message: this.$t('youHaveSuccessfullyRegistered'),
                        })
                        // TODO: refactor
                        this.$store.dispatch('SESSION_CREATE_REQUEST', this.registrationForm)
                            .then(() => {
                                return this.$store.dispatch('PROFILE_GET_REQUEST')
                            })
                            .then(() => {
                                this.$router.push('/')
                            })
                    })
                    .catch(error => {
                        let errorTitle = this.$t('responseErrors.unknownErrorTitle')
                        let errorMessage = this.$t('responseErrors.unknownErrorMessage')

                        if (!error.response) {
                            errorTitle = this.$t('responseErrors.networkErrorTitle')
                            errorMessage = this.$t('responseErrors.networkErrorMessage')
                        }

                        if (error.response && (error.response.status === 400 || error.response.status === 422)) {
                            errorTitle = this.$t('responseErrors.wrongEmailOrInviteTitle')
                            errorMessage = this.$t('responseErrors.wrongEmailOrInviteMessage')
                        }

                        if (error.response && error.response.status >= 500) {
                            errorTitle = this.$t('responseErrors.serverErrorTitle')
                            errorMessage = this.$t('responseErrors.serverErrorMessage')
                        }
                        this.$notify({
                            type: 'error',
                            title: errorTitle,
                            message: errorMessage,
                        })
                    })
            })
        }
    }
}
</script>

<style scoped>
.form-access {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 14px -6px;
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #f2f4f9;
    max-width: 350px;
}

.el-checkbox {
    white-space: normal;
    align-items: baseline;
    display: inline-flex;
    font-weight: initial;
}

.el-checkbox >>> .el-checkbox__label {
    font-size: 16px;
    line-height: 22px;
}
</style>
